import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ViewsActions } from '@app/pages/views/state/views.actions';
import { of, switchMap } from 'rxjs';
import {
	FolderViewQuery,
	ViewFoldersQuery,
} from '@app/pages/views/graphql/views.queries';
import { IViewsFolderWithPaginatedViews } from '@app/pages/views/interfaces/views-folder.interface';
import { catchError, first, map, mergeMap, take } from 'rxjs/operators';
import { IAuPaginated } from '@shared/ui-components/au-paginator/pagination-page.info';
import {
	CreateViewFolderMutation,
	CreateViewInsideFolderMutation,
	DeleteUiTableViewMutation,
	DeleteViewFoldersMutation,
	UpdateViewFolderMutation,
	UpdateViewInsideFolderMutation,
} from '@app/pages/views/graphql/views.mutations';
import { IconSnackBarService } from '@core/services/icon-snack-bar.service';
import { AuErrorAdapter, AuUtilsFunctions } from '@app/shared';
import { Router } from '@angular/router';
import { CmmsTableService } from '@app/pages/cmms/services/cmms-table.service';
import { ViewEntitiesGqlService } from '@app/pages/views/services/view-entities-gql.service';
import { IViewEntityTransformerData } from '@app/pages/views/interfaces/entity-types.interface';
import { Store } from '@ngrx/store';
import { IEntityView } from '@app/pages/views/interfaces/entity-view.interface';
import { TableType } from '@app/pages/cmms/types';
import { getSortKeyWithOrder } from '@app/pages/cmms/utils-and-classes/cmms-util-functions';
import { MyBuildingLocationsService } from '@app/pages/my-building/my-building-locations.service';
import { selectCurrentBuildingId } from './views.reducer';
import {
	FileLoaderService,
	IFileLoaderOptions,
} from '@app/shared/ui-components/au-file-loader/services/file-loader.service';
import { TranslateService } from '@tolgee/ngx';

@Injectable()
export class ViewsEffects {
	private readonly apollo = inject(Apollo);
	private readonly actions$ = inject(Actions);
	private readonly router = inject(Router);
	private readonly store$ = inject(Store);
	private readonly notify = inject(IconSnackBarService);
	private readonly translateService = inject(TranslateService);
	private readonly cmmsTableService = inject(CmmsTableService);
	private readonly gqlService = inject(ViewEntitiesGqlService);
	private readonly locationService = inject(MyBuildingLocationsService);
	private readonly fileLoaderService = inject(FileLoaderService);

	startLoadingViewsFoldersForBuilding$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.loadFoldersForBuilding),
			switchMap(({ buildingId }) => {
				return this.apollo
					.query<{ uiViewFolders: IAuPaginated<IViewsFolderWithPaginatedViews> }>({
						query: ViewFoldersQuery,
						fetchPolicy: 'network-only',
						variables: {
							buildingId,
						},
					})
					.pipe(
						map(res => {
							return res.data.uiViewFolders.edges.map(edge => {
								return {
									...edge.node,
									uiTableViewConfigs: AuUtilsFunctions.extractNodes(
										edge.node.uiTableViewConfigs.edges
									),
								};
							});
						}),
						map(folders => ViewsActions.loadBuildingViewFoldersSuccess({ folders })),
						catchError(error => {
							return of(ViewsActions.loadBuildingViewFoldersFailure({ error }));
						})
					);
			})
		)
	);

	loadViewsFoldersForBuildingFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.loadBuildingViewFoldersFailure),
				map(({ error }) => {
					console.error(AuErrorAdapter.getTextFromGqlError(error));
				})
			),
		{ dispatch: false }
	);

	startLoadingViewsFolders$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.loadFolders),
			switchMap(() => {
				return this.apollo
					.query<{ uiViewFolders: IAuPaginated<IViewsFolderWithPaginatedViews> }>({
						query: ViewFoldersQuery,
						fetchPolicy: 'network-only',
						variables: {
							buildingId_IsNull: true,
						},
					})
					.pipe(
						map(res => {
							return res.data.uiViewFolders.edges.map(edge => {
								return {
									...edge.node,
									uiTableViewConfigs: AuUtilsFunctions.extractNodes(
										edge.node.uiTableViewConfigs.edges
									),
								};
							});
						}),
						map(folders => ViewsActions.loadFoldersSuccess({ folders })),
						catchError(error => {
							return of(ViewsActions.loadFoldersFailure({ error }));
						})
					);
			})
		)
	);

	loadViewsFoldersFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.loadFoldersFailure),
				map(({ error }) => {
					console.error(AuErrorAdapter.getTextFromGqlError(error));
				})
			),
		{ dispatch: false }
	);

	createViewFolder$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.createViewFolder),
			switchMap(({ buildingId, name, description }) => {
				return this.apollo
					.mutate<{
						createUiViewFolder: {
							success: boolean;
							uiViewFolder: IViewsFolderWithPaginatedViews;
						};
					}>({
						mutation: CreateViewFolderMutation,
						variables: {
							...(buildingId && { buildingId }),
							name,
							description,
						},
					})
					.pipe(
						map(res => ({
							success: res.data.createUiViewFolder.success,
							uiViewFolder: {
								...res.data.createUiViewFolder.uiViewFolder,
								uiTableViewConfigs: AuUtilsFunctions.extractNodes(
									res.data.createUiViewFolder.uiViewFolder.uiTableViewConfigs.edges
								),
							},
						})),
						map(data => {
							if (data.success) {
								this.notify.success(
									`${this.translateService.instant('view')} ${this.translateService.instant('has_been_created_successfully')}`
								);
								return ViewsActions.createViewFolderSuccess({
									folder: data.uiViewFolder,
								});
							}
							console.error('Failed to create view');
						}),
						catchError(error => {
							return of(ViewsActions.createViewFolderFailure({ error }));
						})
					);
			})
		)
	);

	createViewFolderSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.createViewFolderSuccess),
				map(({ folder }) => {
					this.router.navigateByUrl(`${this.router.url}/${folder.pk}/create-page`);
				})
			),
		{ dispatch: false }
	);

	createViewFolderFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.createViewFolderFailure),
				map(({ error }) => {
					console.error(AuErrorAdapter.getTextFromGqlError(error));
				})
			),
		{ dispatch: false }
	);

	updateViewFolder$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.updateViewFolder),
			switchMap(({ pk, name, description }) => {
				return this.apollo
					.mutate<{
						updateUiViewFolder: {
							success: boolean;
							uiViewFolder: IViewsFolderWithPaginatedViews;
						};
					}>({
						mutation: UpdateViewFolderMutation,
						variables: {
							pk,
							name,
							description,
						},
					})
					.pipe(
						map(res => ({
							success: res.data.updateUiViewFolder.success,
							uiViewFolder: {
								...res.data.updateUiViewFolder.uiViewFolder,
								uiTableViewConfigs: AuUtilsFunctions.extractNodes(
									res.data.updateUiViewFolder.uiViewFolder.uiTableViewConfigs.edges
								),
							},
						})),
						map(data => {
							if (data.success) {
								this.notify.success(
									this.translateService.instant('folder_edited_success')
								);
								return ViewsActions.updateViewFolderSuccess({
									folder: data.uiViewFolder,
								});
							}
							console.error('Failed to update view folder');
						}),
						catchError(error => {
							return of(ViewsActions.updateViewFolderFailure({ error }));
						})
					);
			})
		)
	);

	updateViewFolderFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.updateViewFolderFailure),
				map(({ error }) => {
					console.error(AuErrorAdapter.getTextFromGqlError(error));
				})
			),
		{ dispatch: false }
	);

	deleteViewFolders$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.deleteViewFolders),
			switchMap(({ folderPks }) => {
				return this.apollo
					.mutate<{
						deleteUiViewFolder: { success: boolean };
					}>({
						mutation: DeleteViewFoldersMutation,
						variables: {
							pks: folderPks,
						},
					})
					.pipe(
						map(res => res.data.deleteUiViewFolder),
						map(data => {
							if (data.success) {
								this.notify.success(
									`View${folderPks.length > 1 ? 's' : ''} has been deleted successfully`
								);
								return ViewsActions.deleteViewFoldersSuccess({
									folderPks,
								});
							}
							console.error('Failed to delete view(s)');
						}),
						catchError(error => {
							return of(ViewsActions.deleteViewFoldersFailure({ error }));
						})
					);
			})
		)
	);

	deleteViewFolderFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.deleteViewFoldersFailure),
				map(({ error }) => {
					console.error(AuErrorAdapter.getTextFromGqlError(error));
				})
			),
		{ dispatch: false }
	);

	deleteView$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.deleteView),
			switchMap(({ view }) => {
				return this.apollo
					.mutate<{
						deleteUiTableViewConfig: { success: boolean };
					}>({
						mutation: DeleteUiTableViewMutation,
						variables: {
							pk: view.pk,
						},
					})
					.pipe(
						map(res => res.data.deleteUiTableViewConfig),
						map(data => {
							if (data.success) {
								this.notify.success(
									this.translateService.instant('view_delete_success', {
										name: view.name,
									})
								);
								return ViewsActions.deleteViewSuccess({ view });
							}
							console.error('Failed to delete view');
						}),
						catchError(error => {
							return of(ViewsActions.deleteViewFailure({ error }));
						})
					);
			})
		)
	);

	deleteViewFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.deleteViewFailure),
				map(({ error }) => {
					console.error(AuErrorAdapter.getTextFromGqlError(error));
				})
			),
		{ dispatch: false }
	);

	createViewInsideFolder$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.createViewInsideFolder),
				map(({ folderPk }) => {
					const currentBuildingId = this.store$.selectSignal(selectCurrentBuildingId);
					let url = '/views/folders-list';
					if (currentBuildingId()) {
						url = `/my-building/${currentBuildingId()}/views/folders-list`;
					}
					this.router.navigateByUrl(`${url}/${folderPk}/create-page`);
				})
			),
		{ dispatch: false }
	);

	createViewInsideFolderFromView$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.createViewInsideFolderFromView),
				map(() => {
					const createViewUrl = this.router.url.split('/').slice(0, -2).join('/');
					this.router.navigateByUrl(`${createViewUrl}/create-page`);
				})
			),
		{ dispatch: false }
	);

	loadCreateViewEntityTableConfig$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.createViewSelectEntityType),
			switchMap(({ entity }) =>
				this.cmmsTableService.getTableColumnsConfig(entity.type).pipe(
					take(1),
					map(tableConfig =>
						ViewsActions.loadCreateViewEntityTableConfigSuccess({ tableConfig })
					),
					catchError(error =>
						of(ViewsActions.loadCreateViewEntityTableConfigFailure({ error }))
					)
				)
			)
		)
	);

	loadCreateViewEntityTableConfigFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.loadCreateViewEntityTableConfigFailure),
				map(({ error }) => {
					console.error(AuErrorAdapter.getTextFromGqlError(error));
				})
			),
		{ dispatch: false }
	);

	loadEntityData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.loadViewEntityTableData),
			switchMap(props =>
				this.locationService.getCurrentLocationIds(selectCurrentBuildingId).pipe(
					first(),
					map(locationIds => ({ ...props, locationIds }))
				)
			),
			switchMap(({ entityType, variables, locationIds }) => {
				let variablesToLoad = { ...variables };
				if (locationIds.length > 0) {
					variablesToLoad = {
						...variables,
						//by idea the one that is not specified in the query should be the omitted and will have no effect, sorry future someone
						locations: variables.locations?.length ? variables.locations : locationIds,
						locationsAsset: variables.locationsAsset?.length
							? variables.locationsAsset
							: locationIds,
					};
				}

				if (!variablesToLoad.first && !variablesToLoad.last) {
					variablesToLoad.first = 50;
				}

				const parsedVariables = this.parseCountRangeValueToDecimal(variablesToLoad);

				return this.gqlService.loadEntityData(entityType, parsedVariables).pipe(
					map((data: IViewEntityTransformerData) =>
						ViewsActions.loadViewEntityTableDataSuccess({
							data: data.entityData,
							pagination: data.entityTablePagination,
						})
					),
					catchError(error => of(ViewsActions.loadViewEntityTableDataFailure({ error })))
				);
			})
		)
	);

	loadEntityDataFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.loadViewEntityTableDataFailure),
				map(({ error }) => {
					console.error(AuErrorAdapter.getTextFromGqlError(error));
				})
			),
		{ dispatch: false }
	);

	createView$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.createView),
			switchMap(({ variables }) => {
				return this.apollo
					.mutate<{
						createUiTableViewConfig: {
							success: boolean;
							uiTableViewConfig: IEntityView;
						};
					}>({
						mutation: CreateViewInsideFolderMutation,
						variables,
					})
					.pipe(
						map(res => res.data.createUiTableViewConfig),
						map(data => {
							if (data.success) {
								this.notify.success(this.translateService.instant('page_create_success'));
								return ViewsActions.createViewSuccess({ view: data.uiTableViewConfig });
							}
							console.error('Failed to create page');
						}),
						catchError(error => {
							return of(ViewsActions.createViewFailure({ error }));
						})
					);
			})
		)
	);

	createViewSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.createViewSuccess),
				map(({ view }) => {
					const url = this.router.url.split('/').slice(0, -1).join('/');
					this.router.navigateByUrl(`${url}/view/${view.pk}`);
				})
			),
		{ dispatch: false }
	);

	createViewFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.createViewFailure),
				map(({ error }) => {
					console.error(AuErrorAdapter.getTextFromGqlError(error));
				})
			),
		{ dispatch: false }
	);

	loadView$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.loadView),
			switchMap(({ viewPk }) => {
				return this.apollo
					.query<{ uiTableViewConfig: IEntityView }>({
						query: FolderViewQuery,
						fetchPolicy: 'network-only',
						variables: {
							pk: viewPk,
						},
					})
					.pipe(
						map(res => res.data.uiTableViewConfig),
						map(view => ViewsActions.loadViewSuccess({ view })),
						catchError(error => of(ViewsActions.loadViewFailure({ error })))
					);
			})
		)
	);

	loadViewSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.loadViewSuccess),
			map(({ view }) => {
				const viewFiltersConfig = JSON.parse(view.filterConfigs);
				const tableType: TableType = view.uiTable.tableType;
				const sortBy = getSortKeyWithOrder(viewFiltersConfig.sort);
				const loadDataVariables = {
					...viewFiltersConfig.variables,
					...(sortBy && { sortBy }),
				};

				//This may be wrong but let it be))
				this.store$.dispatch(
					ViewsActions.loadViewEntityTableData({
						entityType: tableType,
						variables: loadDataVariables,
					})
				);

				return ViewsActions.loadViewEntityTableConfig({ tableType });
			})
		)
	);

	loadViewFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.loadViewFailure),
				map(({ error }) => {
					console.error(AuErrorAdapter.getTextFromGqlError(error));
				})
			),
		{ dispatch: false }
	);

	loadViewDefaultTableConfig$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.loadViewEntityTableConfig),
			switchMap(({ tableType }) =>
				this.cmmsTableService.getTableColumnsConfig(tableType).pipe(
					take(1),
					map(tableConfig =>
						ViewsActions.loadViewEntityTableConfigSuccess({ tableConfig })
					),
					catchError(error =>
						of(ViewsActions.loadViewEntityTableConfigFailure({ error }))
					)
				)
			)
		)
	);

	loadViewEntityTableConfigFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.loadViewEntityTableConfigFailure),
				map(({ error }) => {
					console.error(AuErrorAdapter.getTextFromGqlError(error));
				})
			),
		{ dispatch: false }
	);

	loadUpdateViewEntityTableConfig$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.updateViewChangeEntityType),
			map(({ entity }) =>
				ViewsActions.loadViewEntityTableConfig({ tableType: entity.type })
			)
		)
	);

	loadDefaultViewEntityOnCancelUpdate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.cancelViewUpdate),
			map(({ viewPk, folderPk }) => ViewsActions.loadView({ viewPk, folderPk }))
		)
	);

	updateView$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.updateView),
			switchMap(({ variables }) => {
				return this.apollo
					.mutate<{
						updateUiTableViewConfig: {
							success: boolean;
							uiTableViewConfig: IEntityView;
						};
					}>({
						mutation: UpdateViewInsideFolderMutation,
						variables,
					})
					.pipe(
						map(res => res.data.updateUiTableViewConfig),
						map(data => {
							if (data.success) {
								this.notify.success(this.translateService.instant('page_edit_success'));
								return ViewsActions.updateViewSuccess({ view: data.uiTableViewConfig });
							}
							console.error('Failed to update page');
						}),
						catchError(error => {
							return of(ViewsActions.updateViewFailure({ error }));
						})
					);
			})
		)
	);

	updateViewSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.updateViewSuccess),
			map(({ view }) => {
				return ViewsActions.loadView({ viewPk: view.pk, folderPk: view.viewFolder.pk });
			})
		)
	);

	updateViewFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ViewsActions.updateViewFailure),
				map(({ error }) => {
					console.error(AuErrorAdapter.getTextFromGqlError(error));
				})
			),
		{ dispatch: false }
	);

	openFileUploader$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ViewsActions.openFileUploader),
			map(({ locations }) => {
				if (locations) {
					const fileLoaderOptions: Partial<IFileLoaderOptions> = {
						mutationVariables: {
							locations,
						},
					};
					return fileLoaderOptions;
				}
			}),
			mergeMap(options =>
				this.fileLoaderService.createFileLoaderFloatingWindow(undefined, options)
			),
			map(result => ViewsActions.fileUploaderEntityChange(result))
		)
	);

	private parseCountRangeValueToDecimal(variablesToLoad): any {
		let variables = structuredClone(variablesToLoad);

		if (variables.costOfWork) {
			const costOfWork = variables.costOfWork;

			variables = {
				...variables,
				costOfWork_Gte: this.getValidValueFromCostFilter(costOfWork.costOfWork_Gte),
				costOfWork_Lte: this.getValidValueFromCostFilter(costOfWork.costOfWork_Lte),
			};

			delete variables.costOfWork;
		}

		if (variables.costOfRepair) {
			const costOfRepair = variables.costOfRepair;

			variables = {
				...variables,
				costOfRepair_Gte: this.getValidValueFromCostFilter(costOfRepair.costOfRepair_Gte),
				costOfRepair_Lte: this.getValidValueFromCostFilter(costOfRepair.costOfRepair_Lte),
			};

			delete variables.costOfRepair;
		}

		return variables;
	}

	private getValidValueFromCostFilter(value) {
		return value || (value?.length && +value === 0) ? Number(value).toFixed(2) : null;
	}
}
