import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, mergeMap, of } from 'rxjs';
import {
	catchError,
	exhaustMap,
	filter,
	map,
	switchMap,
	withLatestFrom,
} from 'rxjs/operators';
import {
	addFileExternalData,
	attachToFileSuccess,
	createDetectionTask,
	createFileError,
	createFileSuccess,
	deletingFilesSuccess,
	deletingFileSuccess,
	editFileSuccess,
	filesFailedToLoad,
	filesLoaded,
	loadDefaultFiles,
	openFileEntityWorkspaceFromOutside,
	openNewFileEntityWorkspaceFromOutside,
	reloadFiles,
	reloadFilesWithSavePagination,
	retrieveDetectionTask,
	setDetectionTask,
	startDeletingFile,
	startDeletingFiles,
	startEditFile,
	startLoadingFiles,
	updateDetectionTask,
	updateFile,
	updateFileInsideFileEntity,
} from '@app/pages/cmms/components/files/state/files.action';
import { AuRightSidebarService } from '@core/services/au-right-sidebar.service';
import { AuErrorService } from '@core/services/au-error-service';
import { IconSnackBarService } from '@core/services/icon-snack-bar.service';
import { AuErrorAdapter } from '@app/shared';
import { Store } from '@ngrx/store';
import {
	filesQueryVariables,
	selectDetectionTaskResult,
} from '@app/pages/cmms/components/files/state/files.selectors';
import { FilesService } from '@app/pages/cmms/services/files.service';
import { IFileState } from '@app/pages/cmms/components/files/state/files.reducer';
import { IFileEntity } from '@app/pages/cmms/interfaces/files.interfaces';
import { IncidentDetectionService } from '@app/pages/cmms/services/incident-detection.service';
import {
	DetectionTaskStatus,
	IIncidentDetection,
	IResultData,
} from '@app/pages/cmms/interfaces/incident-detection.interfaces';
import {
	createIncidentSuccess,
	startDeletingIncident,
} from '../../incident/state/incidents.actions';
import { RightSidebarNavigationService } from '@app/layout/au-right-sidebar/right-sidebar-navigation.service';
import { ActionCenterService } from '@app/core/services/action-center.service';
import { defaultPageSize } from '@app/pages/cmms/configs/pagination-query-variables';
import {
	FileLoaderService,
	IFileLoaderOptions,
} from '@app/shared/ui-components/au-file-loader/services/file-loader.service';
import { TranslateService } from '@tolgee/ngx';

const messagesForReloadingFilesTable = {
	'[FILES] Start reloading files': 'Files added successfully',
	'[FILES] Deleting file success': 'File deleted successfully',
	'[FILES] Deleting files success': 'Files deleted successfully',
};

@Injectable()
export class FilesEffects {
	constructor(
		private actions$: Actions,
		private fileService: FilesService,
		private fileLoaderService: FileLoaderService,
		private notify: IconSnackBarService,
		private sidebarService: AuRightSidebarService,
		private errorService: AuErrorService,
		private incidentDetectionService: IncidentDetectionService,
		private store$: Store<IFileState>,
		private sidebarNavigationService: RightSidebarNavigationService,
		private actionCenterService: ActionCenterService,
		private translateService: TranslateService
	) {}

	fileOpened$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(loadDefaultFiles),
			map(() => {
				return startLoadingFiles({ variables: {} });
			})
		);
	});

	startLoadingFiles$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(startLoadingFiles),
			map(({ variables }) => {
				if (variables?.first || variables?.last) {
					return variables;
				}
				return {
					...variables,
					first: defaultPageSize,
				};
			}),
			switchMap(variables => {
				return this.fileService.loadFileEntities(variables).pipe(
					map(({ files, filesPagination }) => {
						return filesLoaded({ files, filesPagination });
					}),
					catchError(error => {
						this.notify.error(this.translateService.instant('error_generic_refresh'));
						return of(filesFailedToLoad());
					})
				);
			})
		);
	});

	reloadFilesWithSavePagination$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(reloadFilesWithSavePagination),
			withLatestFrom(this.store$.select(filesQueryVariables)),
			map(([_, variables]) => {
				return startLoadingFiles({ variables });
			}),
			catchError(() => {
				return EMPTY;
			})
		);
	});

	createFileSuccess$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(createFileSuccess),
			withLatestFrom(this.store$.select(filesQueryVariables)),
			map(([_, variables]) => {
				this.notify.success(
					`${this.translateService.instant('file')} ${this.translateService.instant('has_been_created_successfully')}`
				);
				return startLoadingFiles({ variables });
			}),
			catchError(error => {
				this.notify.error(this.translateService.instant('error_generic_refresh'));
				return EMPTY;
			})
		);
	});

	createFileError$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(createFileError),
				map(({ error }) => {
					if (error) {
						this.errorService.notifyUserAboutError({
							error: error,
							defaultMessage: this.translateService.instant('error_generic_try_again'),
						});
					}
					this.notify.error(this.translateService.instant('error_generic_refresh'));
				}),
				catchError(error => {
					this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
					return EMPTY;
				})
			);
		},
		{ dispatch: false }
	);

	startEditFiles$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(startEditFile),
			mergeMap(({ file, hideSuccessNotification, isAttaching }) => {
				return this.fileService.editFile(file).pipe(
					map(file => {
						if (!hideSuccessNotification) {
							this.notify.success(
								`${this.translateService.instant('file')} ${this.translateService.instant('has_been_updated_successfully')}`
							);
						}
						this.actionCenterService.actionDone$.next();
						return editFileSuccess({ file, isAttaching });
					}),
					catchError(error => {
						this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
						return EMPTY;
					})
				);
			})
		);
	});

	startReplacingFile$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(updateFileInsideFileEntity),
			exhaustMap(({ newFile, fileEntity }) => {
				return this.fileService
					.replaceFileInsideEntity(newFile, fileEntity.pk, fileEntity.name)
					.pipe(
						map((file: IFileEntity) => {
							this.notify.success(
								`${this.translateService.instant('file')} ${this.translateService.instant('has_been_replaced_successfully')}`
							);
							return editFileSuccess({ file });
						}),
						catchError(error => {
							this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
							return EMPTY;
						})
					);
			})
		);
	});

	editFileSuccess$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(editFileSuccess),
			map(({ file, isAttaching }) => {
				if (isAttaching) {
					this.store$.dispatch(attachToFileSuccess());
				}
				this.actionCenterService.actionDone$.next();
				return updateFile({
					update: {
						// this is id of entity adapter!!! this code is correct;
						id: file.pk,
						changes: file,
					},
				});
			})
		);
	});

	attachToFileSuccess$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(attachToFileSuccess),
				map(() => {
					this.sidebarNavigationService.navigateToPreviousPage();
				})
			);
		},
		{ dispatch: false }
	);

	startDeletingFile$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(startDeletingFile),
			mergeMap(({ pk }) => {
				return this.fileService.deleteFiles([pk]).pipe(
					map(() => {
						this.sidebarService.close();
						return deletingFileSuccess({ pk });
					}),
					catchError(error => {
						this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
						return EMPTY;
					})
				);
			})
		);
	});

	startDeletingFiles$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(startDeletingFiles),
			mergeMap(({ pks }) => {
				return this.fileService.deleteFiles(pks).pipe(
					map(() => {
						this.sidebarService.close();
						return deletingFilesSuccess({ pks });
					}),
					catchError(error => {
						this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
						return EMPTY;
					})
				);
			})
		);
	});

	deletingFileSuccess$ = createEffect((): any => {
		return this.actions$.pipe(
			ofType(deletingFileSuccess, deletingFilesSuccess),
			map(() => {
				this.notify.success(
					`${this.translateService.instant('file')} ${this.translateService.instant('has_been_deleted_successfully')}`
				);
				return reloadFilesWithSavePagination();
			}),
			catchError(error => {
				this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
				return EMPTY;
			})
		);
	});

	fileActionsSuccess$ = createEffect((): any => {
		return this.actions$.pipe(
			ofType(reloadFiles, deletingFileSuccess, deletingFilesSuccess),
			withLatestFrom(this.store$.select(filesQueryVariables)),
			map(([action, variables]) => {
				this.notify.success(messagesForReloadingFilesTable[action.type]);
				return startLoadingFiles({ variables });
			}),
			catchError(error => {
				this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
				return EMPTY;
			})
		);
	});

	createDetectionTask$ = createEffect((): any => {
		return this.actions$.pipe(
			ofType(createDetectionTask),
			mergeMap(({ variables }) => {
				return this.incidentDetectionService.createDetectionTask(variables as any).pipe(
					map(result => {
						return setDetectionTask({
							result: result,
							detectionTaskStatus: DetectionTaskStatus.CREATED,
						});
					}),
					catchError(() => {
						this.notify.error(this.translateService.instant('error_generic_refresh'));
						return of(
							setDetectionTask({
								result: {
									id: null,
									fileId: variables.fileId,
									locationId: null,
									performedBy: null,
									results: 'error',
								},
								detectionTaskStatus: DetectionTaskStatus.ERROR,
							})
						);
					})
				);
			})
		);
	});

	retrieveDetectionTask$ = createEffect((): any => {
		return this.actions$.pipe(
			ofType(retrieveDetectionTask),
			mergeMap(({ fileId }) => {
				return this.incidentDetectionService
					.retrieveDetectionTask({ fileId } as IIncidentDetection)
					.pipe(
						map(result => {
							return setDetectionTask({
								result: result,
								detectionTaskStatus: DetectionTaskStatus.RETRIEVED,
							});
						}),
						catchError(() => {
							return EMPTY;
						})
					);
			})
		);
	});

	updateDetectionTask$ = createEffect((): any => {
		return this.actions$.pipe(
			ofType(updateDetectionTask),
			withLatestFrom(this.store$.select(selectDetectionTaskResult)),
			mergeMap(([{ updateVariables }, detectionTaskResults]) => {
				const results = detectionTaskResults[updateVariables.fileId];
				const { deviation: parsedResults } = JSON.parse(results.results) as {
					deviation: IResultData[];
				};
				const indx = parsedResults.findIndex(
					result => result.ID === updateVariables.resultId
				);
				if (updateVariables.result) {
					parsedResults[indx] = updateVariables.result[indx];
				}
				parsedResults[indx] = {
					...parsedResults[indx],
					...updateVariables.updateValues,
				};
				const updatedResults = JSON.stringify({
					deviation: parsedResults,
				});
				return this.incidentDetectionService
					.updateDetectionResult({
						fileId: updateVariables.fileId,
						results: updatedResults,
					})
					.pipe(
						map(() => {
							return retrieveDetectionTask({
								fileId: updateVariables.fileId,
							});
						}),
						catchError(error => {
							console.error('An error occurred', error);
							return EMPTY;
						})
					);
			})
		);
	});

	deleteIncident$ = createEffect((): any => {
		return this.actions$.pipe(
			ofType(updateDetectionTask),
			filter(({ updateVariables }) => updateVariables.updateValues.status === 'DELETED'),
			withLatestFrom(this.store$.select(selectDetectionTaskResult)),
			map(([{ updateVariables }, detectionTaskResults]) => {
				const results = detectionTaskResults[updateVariables.fileId];
				const { deviation: parsedResults } = JSON.parse(results.results) as {
					deviation: IResultData[];
				};
				const incidentInfo = parsedResults.find(
					result => result.ID === updateVariables.resultId
				);

				return startDeletingIncident({
					pk: incidentInfo.incident.pk,
					closeSidebar: false,
				});
			})
		);
	});

	setIncidentTaskArrayStatus$ = createEffect((): any => {
		return this.actions$.pipe(
			ofType(createIncidentSuccess),
			filter(({ dexterSource }) => !!dexterSource),
			map(({ incident, dexterSource }) => {
				const incidentPkAndId = { pk: incident.pk, id: incident.incidentId };
				this.store$.dispatch(
					startEditFile({
						file: { pk: dexterSource.fileId, incidents: [incident.pk] },
						hideSuccessNotification: true,
					})
				);
				return updateDetectionTask({
					updateVariables: {
						fileId: dexterSource.fileId,
						resultId: dexterSource.resultId,
						result: dexterSource.result,
						updateValues: {
							incident: incidentPkAndId,
							status: 'CREATED',
						},
					},
				});
			})
		);
	});

	addExternalData$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(addFileExternalData),
			exhaustMap(({ fileData }) => {
				return this.fileService.editFile(fileData).pipe(
					map(file => {
						this.notify.success(
							`${this.translateService.instant('file')} ${this.translateService.instant('has_been_updated_successfully')}`
						);
						return editFileSuccess({ file });
					}),
					catchError(error => {
						this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
						return EMPTY;
					})
				);
			})
		);
	});

	openFileWorkspaceFromOutside$ = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(openFileEntityWorkspaceFromOutside),
				exhaustMap(({ pk }) => {
					return this.fileService.getFileEntityByPk(pk);
				}),
				map((file: IFileEntity) => {
					if (!file) {
						this.notify.error(
							`${this.translateService.instant('object')} ${this.translateService.instant('does_not_exist')}`
						);
					} else {
						this.fileService.viewFileInWorkspace(file);
					}
				}),
				catchError(error => {
					this.notify.error(AuErrorAdapter.getTextFromGqlError(error));
					return EMPTY;
				})
			);
		},
		{ dispatch: false }
	);

	openNewFileWorkspaceFromOutside$ = createEffect(() =>
		this.actions$.pipe(
			ofType(openNewFileEntityWorkspaceFromOutside),
			map(({ fileData }) => {
				if (fileData?.locations) {
					const fileLoaderOptions: Partial<IFileLoaderOptions> = {
						mutationVariables: {
							locations: fileData.locations,
						},
					};
					return fileLoaderOptions;
				}
			}),
			mergeMap(options =>
				this.fileLoaderService.createFileLoaderFloatingWindow(undefined, options)
			),
			map(event => {
				if (event.eventType === 'loaded') {
					return createFileSuccess(undefined);
				}
				if (event.eventType === 'deleted') {
					return deletingFilesSuccess({ pks: event.fileIds });
				}
				return reloadFiles();
			})
		)
	);
}
